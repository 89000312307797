import React, { createContext,Component } from "react";
import axios from 'axios';
import { WEB_URL } from './Apilinks';
export const MyContext = createContext();

// Define the base URL
const Axios = axios.create({
    baseURL: WEB_URL,
});

class MyContextProvider extends Component{
    state = {
        isAuthPro:false,
        theUserProID:null,
    }
    componentDidMount(){
        this.isLoggedIn();
    }
  
    isLoggedIn = async () => {
        let loginID = JSON.parse(localStorage.getItem('loginID'));
        if (localStorage.getItem('loginID')) {
            this.setState({
                ...this.state,
                isAuthPro:true,
                theUserProID:loginID,
            });
        }
    }
    logoutUser = () => {
        localStorage.removeItem('loginID');
        this.setState({
            ...this.state,
            isAuthPro:false,
            theUserProID:null,
        })
        window.location.href = '/LoginSection';
    }
    RegisterUser = async (userInfo,recaptcha_response) => {
        const registerqqq = await Axios.post('/login/register.php',{
            user_name:userInfo.name,
            user_email:userInfo.email,
            password:userInfo.password,
            subscribe:userInfo.subscribe,
            recaptcha_response:recaptcha_response,
        });
        return registerqqq.data;
    }
    LoginUser = async (user) => {
        const LoginUserqqq = await Axios.post('/login/login.php',{
            email:user.email,
            password:user.password,
        });
        return LoginUserqqq.data;
    }
    ForgetMailpassword = async (user) => {
        const loginForget = await Axios.post('/login/forgetpassword.php',{
            email:user.email,
        });
        return loginForget.data;
    }
    ChangePasswordall = async (user,passkey) => {
        const ChangePasswordallsss = await Axios.post('/login/changePasswordall.php',{
            newpassword:user.password,
            passkeyids:passkey,
        });
        return ChangePasswordallsss.data;
    }
    userdetailsall = async (userid) => {
        const userdetailsallsss = await Axios.post('userdetailsall.php',{
            userids:userid,
        });
        return userdetailsallsss.data;
    }
    editprofileall = async (books,purchase,purchagelength,userids) => {
        const formData = new FormData();
        formData.append('name',books.name);
        formData.append('avatar',books.singlefile);
        formData.append('website',books.website);
        formData.append('abouts',books.abouts);
        formData.append('purchase',purchase);
        formData.append('purchagelength',purchagelength.length);
        formData.append('userids',userids);
        const editprofileallsss = await Axios.post('editprofile.php',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return editprofileallsss.data;
    }
    Booksaddall = async (books,purchase,purchagelength,theUserid) => {
        const formData = new FormData();
        formData.append('title',books.title);
        formData.append('avatar',books.singlefile);
        formData.append('purchase',purchase);
        formData.append('purchagelength',purchagelength.length);
        formData.append('category',books.category);
        formData.append('descothers',books.descothers);
        formData.append('price',books.price);
        formData.append('currency',books.currency);
        formData.append('website',books.website);
        formData.append('abouts',books.abouts);
        formData.append('theUserid',theUserid);
        const Booksaddallqqq = await Axios.post('booksadd.php',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return Booksaddallqqq.data;
    }
    userbooksall = async (userid) => {
        const userbooksallsss = await Axios.post('userbooksall.php',{
            userids:userid,
        });
        return userbooksallsss.data;
    }
    bookgetidall = async (ids) => {
        const bookgetidallqqq = await Axios.post('bookeditall.php',{
            ids:ids,
        });
        return bookgetidallqqq.data;
    }
    editbookall = async (books,purchase,purchagelength,bookids) => {
        const formData = new FormData();
        formData.append('title',books.title);
        formData.append('avatar',books.singlefile);
        formData.append('purchase',purchase);
        formData.append('purchagelength',purchagelength.length);
        formData.append('category',books.category);
        formData.append('descothers',books.descothers);
        formData.append('price',books.price);
        formData.append('currency',books.currency);
        formData.append('website',books.website);
        formData.append('abouts',books.abouts);
        formData.append('bookids',bookids);
        const editbookallqqq = await Axios.post('booksupdate.php',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return editbookallqqq.data;
    }
    AudioBooksaddall = async (books,purchase,purchagelength,theUserid) => {
        const formData = new FormData();
        formData.append('title',books.title);
        formData.append('avatar',books.singlefile);
        formData.append('purchase',purchase);
        formData.append('purchagelength',purchagelength.length);
        formData.append('category',books.category);
        formData.append('descothers',books.descothers);
        formData.append('price',books.price);
        formData.append('currency',books.currency);
        formData.append('website',books.website);
        formData.append('abouts',books.abouts);
        formData.append('written',books.written);
        formData.append('narrated',books.narrated);
        formData.append('hrs',books.hrs);
        formData.append('mins',books.mins);
        formData.append('theUserid',theUserid);
        const AudioBooksaddallqqq = await Axios.post('audiobooksadd.php',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return AudioBooksaddallqqq.data;
    }
    editaudiobookall = async (books,purchase,purchagelength,bookids) => {
        const formData = new FormData();
        formData.append('title',books.title);
        formData.append('avatar',books.singlefile);
        formData.append('purchase',purchase);
        formData.append('purchagelength',purchagelength.length);
        formData.append('category',books.category);
        formData.append('descothers',books.descothers);
        formData.append('price',books.price);
        formData.append('currency',books.currency);
        formData.append('website',books.website);
        formData.append('abouts',books.abouts);
        formData.append('written',books.written);
        formData.append('narrated',books.narrated);
        formData.append('hrs',books.hrs);
        formData.append('mins',books.mins);
        formData.append('bookids',bookids);
        const editaudiobookallqqq = await Axios.post('audiobooksupdate.php',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return editaudiobookallqqq.data;
    }
    booktrailersall = async (userid) => {
        const booktrailersallsss = await Axios.post('booktrailersall.php',{
            userids:userid,
        });
        return booktrailersallsss.data;
    }
    Booktraileraddall = async (books,userid) => {
        const Booktraileraddallsss = await Axios.post('booktrailersadd.php',{
            title: books.title,
            url: books.url,
            userids:userid,
        });
        return Booktraileraddallsss.data;
    }
    booktrailergetidall = async (ids) => {
        const booktrailergetidallsss = await Axios.post('edittrailerlist.php',{
            ids:ids,
        });
        return booktrailergetidallsss.data;
    }
    Booktrailereditall = async (books,videoid) => {
        const Booktrailereditallsss = await Axios.post('booktrailersedit.php',{
            title: books.title,
            url: books.url,
            videoid:videoid,
        });
        return Booktrailereditallsss.data;
    }
    bookpaysmsg = async (payids) => {
        const bookpaysmsgqqq = await Axios.post('stripesuccess.php',{
            payids:payids,
        });
        return bookpaysmsgqqq.data;
    }
    bookpaypalmsg = async (payids) => {
        const bookpaypalmsgqqq = await Axios.post('paypalsuccess.php',{
            payids:payids,
        });
        return bookpaypalmsgqqq.data;
    }
    userpublishersall = async (userid) => {
        const userpublishersallsss = await Axios.post('userpublishersall.php',{
            userids:userid,
        });
        return userpublishersallsss.data;
    }
    userpoemall = async (userid) => {
        const userpoemallsss = await Axios.post('userpoemall.php',{
            userids:userid,
        });
        return userpoemallsss.data;
    }
    userarticleall = async (userid) => {
        const userarticleallsss = await Axios.post('userarticleall.php',{
            userids:userid,
        });
        return userarticleallsss.data;
    }
    publisheraddall = async (books,theUserid) => {
        const formData = new FormData();
        formData.append('title',books.title);
        formData.append('avatar',books.singlefile);
        formData.append('email',books.email);
        formData.append('phone',books.phone);
        formData.append('website',books.website);
        formData.append('abouts',books.abouts);
        formData.append('theUserid',theUserid);
        const publisheraddallsss = await Axios.post('publisheradd.php',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return publisheraddallsss.data;
    }
    articleaddall = async (books,theUserid) => {
        const formData = new FormData();
        formData.append('title',books.title);
        formData.append('avatar',books.singlefile);
        formData.append('abouts',books.abouts);
        formData.append('theUserid',theUserid);
        const articleaddallsss = await Axios.post('articleadd.php',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return articleaddallsss.data;
    }
    poemaddall = async (books,theUserid) => {
        const poemaddallsss = await Axios.post('poemadd.php',{
            title:books.title,
            abouts:books.abouts,
            theUserid:theUserid,
        });
        return poemaddallsss.data;
    }
    pubsgetidall = async (ids) => {
        const pubsgetidallsss = await Axios.post('pubsgetidall.php',{
            ids:ids,
        });
        return pubsgetidallsss.data;
    }
    poemgetidall = async (ids) => {
        const poemgetidallsss = await Axios.post('poemgetidall.php',{
            ids:ids,
        });
        return poemgetidallsss.data;
    }
    articlegetidall = async (ids) => {
        const articlegetidallsss = await Axios.post('articlegetidall.php',{
            ids:ids,
        });
        return articlegetidallsss.data;
    }
    publishereditall = async (books,theUserid) => {
        const formData = new FormData();
        formData.append('title',books.title);
        formData.append('avatar',books.singlefile);
        formData.append('email',books.email);
        formData.append('phone',books.phone);
        formData.append('website',books.website);
        formData.append('abouts',books.abouts);
        formData.append('theUserid',theUserid);
        const publishereditallsss = await Axios.post('publisheredit.php',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return publishereditallsss.data;
    }
    articleeditall = async (books,theUserid) => {
        const formData = new FormData();
        formData.append('title',books.title);
        formData.append('avatar',books.singlefile);
        formData.append('abouts',books.abouts);
        formData.append('theUserid',theUserid);
        const articleeditallsss = await Axios.post('articleedit.php',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return articleeditallsss.data;
    }
    poemeditall = async (books,theUserid) => {
        const poemeditallsss = await Axios.post('poemedit.php',{
            title:books.title,
            abouts:books.abouts,
            theUserid:theUserid,
        });
        return poemeditallsss.data;
    }
    trailerpaysmsg = async (payids) => {
        const trailerpaysmsgqqq = await Axios.post('trailerpaysmsg.php',{
            payids:payids,
        });
        return trailerpaysmsgqqq.data;
    }
    trailerpaysmsgpaypal = async (payids) => {
        const trailerpaysmsgpaypalqqq = await Axios.post('trailerpaysmsgpaypal.php',{
            payids:payids,
        });
        return trailerpaysmsgpaypalqqq.data;
    }
    publisherpaysmsg = async (payids) => {
        const publisherpaysmsgqqq = await Axios.post('publisherpaysmsg.php',{
            payids:payids,
        });
        return publisherpaysmsgqqq.data;
    } 
    publisherpaysmsgpaypal = async (payids) => {
        const publisherpaysmsgpaypalqqq = await Axios.post('publisherpaysmsgpaypal.php',{
            payids:payids,
        });
        return publisherpaysmsgpaypalqqq.data;
    }
    bookpayreceipt = async (payids) => {
        const bookpayreceiptsss = await Axios.post('payreceipts.php',{
            payids:payids,
        });
        return bookpayreceiptsss.data;
    }
    interviewaddall = async (books,purchase,purchagelength,theUserid,types,qas) => {
        const formData = new FormData();
        formData.append('videos',books.videos);
        formData.append('title',books.title);
        formData.append('avatar',books.singleimg);
        formData.append('avatarimgactive',books.singleimgactive);
        // formData.append('avatar',books.singlefile);
        formData.append('purchase',purchase);
        formData.append('purchagelength',purchagelength.length);
        formData.append('theUserid',theUserid);
        formData.append('types',types);
        formData.append('qone',qas.qone === undefined ? '': qas.qone);
        formData.append('qtwo',qas.qtwo === undefined ? '': qas.qtwo);
        formData.append('qthree',qas.qthree === undefined ? '': qas.qthree);
        formData.append('qfour',qas.qfour === undefined ? '': qas.qfour);
        formData.append('qfive',qas.qfive === undefined ? '': qas.qfive);
        formData.append('qsix',qas.qsix === undefined ? '': qas.qsix);
        formData.append('qseven',qas.qseven === undefined ? '': qas.qseven);
        formData.append('qeight',qas.qeight === undefined ? '': qas.qeight);
        formData.append('qnine',qas.qnine === undefined ? '': qas.qnine);
        formData.append('qten',qas.qten === undefined ? '': qas.qten);
        formData.append('qellavan',qas.qellavan === undefined ? '': qas.qellavan);
        formData.append('qtwelve',qas.qtwelve === undefined ? '': qas.qtwelve);
        formData.append('qthirteen',qas.qthirteen === undefined ? '': qas.qthirteen);
        formData.append('qfourteen',qas.qfourteen === undefined ? '': qas.qfourteen);
        formData.append('qfiveteen',qas.qfiveteen === undefined ? '': qas.qfiveteen);
        formData.append('qsixteen',qas.qsixteen === undefined ? '': qas.qsixteen);
        formData.append('qseventeen',qas.qseventeen === undefined ? '': qas.qseventeen);
        formData.append('qeighteen',qas.qeighteen === undefined ? '': qas.qeighteen);
        formData.append('qnineteen',qas.qnineteen === undefined ? '': qas.qnineteen);
        formData.append('qtwinty',qas.qtwinty === undefined ? '': qas.qtwinty);
        formData.append('qtwintyone',qas.qtwintyone === undefined ? '': qas.qtwintyone);
        formData.append('qtwintytwo',qas.qtwintytwo === undefined ? '': qas.qtwintytwo);
        const interviewaddallqqq = await Axios.post('interviewsadd.php',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return interviewaddallqqq.data;
    }
    interviewaddtwoall = async (books,theUserid,types) => {
        const formData = new FormData();
        formData.append('title',books.title);
        formData.append('avatarimg',books.singleimg);
        formData.append('avatarimgactive',books.singleimgactive);
        formData.append('avatar',books.singlefile);
        formData.append('theUserid',theUserid);
        formData.append('types',types);
        const interviewaddtwoallqqq = await Axios.post('interviewsaddtwo.php',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return interviewaddtwoallqqq.data;
    }
    userinterviewsall = async (ids) => {
        const userinterviewsallsss = await Axios.post('userinterviews.php',{
            userids:ids,
        });
        return userinterviewsallsss.data;
    }
    interviewsgetidall = async (ids) => {
        const interviewsgetidallqqq = await Axios.post('interviewsgetids.php',{
            ids:ids,
        });
        return interviewsgetidallqqq.data;
    }
    intervieweditall = async (books,purchase,purchagelength,theUserid,qas) => {
        const formData = new FormData();
        formData.append('videos',books.videos);
        formData.append('avatar',books.singleimg);
        formData.append('purchase',purchase);
        formData.append('purchagelength',purchagelength.length);
        formData.append('theUserid',theUserid);
        formData.append('title',books.title);
        formData.append('avatarimgactive',books.singleimgactive);
        formData.append('qone',qas.qone === undefined ? '': qas.qone);
        formData.append('qtwo',qas.qtwo === undefined ? '': qas.qtwo);
        formData.append('qthree',qas.qthree === undefined ? '': qas.qthree);
        formData.append('qfour',qas.qfour === undefined ? '': qas.qfour);
        formData.append('qfive',qas.qfive === undefined ? '': qas.qfive);
        formData.append('qsix',qas.qsix === undefined ? '': qas.qsix);
        formData.append('qseven',qas.qseven === undefined ? '': qas.qseven);
        formData.append('qeight',qas.qeight === undefined ? '': qas.qeight);
        formData.append('qnine',qas.qnine === undefined ? '': qas.qnine);
        formData.append('qten',qas.qten === undefined ? '': qas.qten);
        formData.append('qellavan',qas.qellavan === undefined ? '': qas.qellavan);
        formData.append('qtwelve',qas.qtwelve === undefined ? '': qas.qtwelve);
        formData.append('qthirteen',qas.qthirteen === undefined ? '': qas.qthirteen);
        formData.append('qfourteen',qas.qfourteen === undefined ? '': qas.qfourteen);
        formData.append('qfiveteen',qas.qfiveteen === undefined ? '': qas.qfiveteen);
        formData.append('qsixteen',qas.qsixteen === undefined ? '': qas.qsixteen);
        formData.append('qseventeen',qas.qseventeen === undefined ? '': qas.qseventeen);
        formData.append('qeighteen',qas.qeighteen === undefined ? '': qas.qeighteen);
        formData.append('qnineteen',qas.qnineteen === undefined ? '': qas.qnineteen);
        formData.append('qtwinty',qas.qtwinty === undefined ? '': qas.qtwinty);
        formData.append('qtwintyone',qas.qtwintyone === undefined ? '': qas.qtwintyone);
        formData.append('qtwintytwo',qas.qtwintytwo === undefined ? '': qas.qtwintytwo);
        const intervieweditallqqq = await Axios.post('interviewsedit.php',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return intervieweditallqqq.data;
    }
    intervieweditalltwo = async (books,theUserid) => {
        const formData = new FormData();
        formData.append('title',books.title);
        formData.append('avatarimg',books.singleimg);
        formData.append('avatarimgactive',books.singleimgactive);
        formData.append('avatar',books.singlefile);
        formData.append('theUserid',theUserid);
        const intervieweditalltwoqqq = await Axios.post('interviewsedittwo.php',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return intervieweditalltwoqqq.data;
    }
    emailverifysall = async (userInfo,ids) => {
        const emailverifysallqqq = await Axios.post('login/emailverifys.php',{
            sms:userInfo.sms,
            ids:ids,
        });
        return emailverifysallqqq.data;
    }
    interviewsvideohomeall = async () => {
        const interviewsvideohomeallsss = await Axios.get('home/interviewsvideohome.php');
        return interviewsvideohomeallsss.data;
    }
    interviewshomeall = async () => {
        const interviewshomeallsss = await Axios.get('home/interviewshome.php');
        return interviewshomeallsss.data;
    }
    bookshomeall = async () => {
        const bookshomeallsss = await Axios.get('home/bookshome.php');
        return bookshomeallsss.data;
    }
    bookstrailerhomeall = async () => {
        const bookstrailerhomeallsss = await Axios.get('home/bookstrailerhome.php');
        return bookstrailerhomeallsss.data;
    }
    articlehomeall = async () => {
        const articlehomeallsss = await Axios.get('home/articlehome.php');
        return articlehomeallsss.data;
    }
    poemhomeall = async () => {
        const poemhomeallsss = await Axios.get('home/poemhome.php');
        return poemhomeallsss.data;
    }
    publisherhomeall = async () => {
        const publisherhomeallsss = await Axios.get('home/publisherhome.php');
        return publisherhomeallsss.data;
    }
    qavideohomeall = async () => {
        const qavideohomeallsss = await Axios.get('home/qavideohome.php');
        return qavideohomeallsss.data;
    }
    AllArticlelist  = async (pageNumber) => {
        const AllArticlelistsss = await Axios.get(`page/allarticlelist.php?page=${pageNumber}`);
        return AllArticlelistsss.data;
    }
    AllPeomlist  = async (pageNumber) => {
        const AllPeomlistsss = await Axios.get(`page/allpoemlist.php?page=${pageNumber}`);
        return AllPeomlistsss.data;
    }
    Allbooktrailerlist  = async (pageNumber) => {
        const Allbooktrailerlistsss = await Axios.get(`page/allbooktrailerlist.php?page=${pageNumber}`);
        return Allbooktrailerlistsss.data;
    }
    Allinterviewlist  = async (pageNumber) => {
        const Allinterviewlistsss = await Axios.get(`page/allinterviewlist.php?page=${pageNumber}`);
        return Allinterviewlistsss.data;
    }
    Allaudiobookslist  = async (pageNumber) => {
        const Allaudiobookslistsss = await Axios.get(`page/allaudiobookslist.php?page=${pageNumber}`);
        return Allaudiobookslistsss.data;
    }
    Allbookslist  = async (pageNumber) => {
        const Allbookslistsss = await Axios.get(`page/allbookslist.php?page=${pageNumber}`);
        return Allbookslistsss.data;
    }
    Allpublisherlist  = async (pageNumber) => {
        const Allpublisherlistsss = await Axios.get(`page/allpublisherlist.php?page=${pageNumber}`);
        return Allpublisherlistsss.data;
    }    
    bookpreviewall = async (ids) => {
        const bookpreviewallsss = await Axios.post('page/bookpreviewall.php',{
            ids:ids,
        });
        return bookpreviewallsss.data;
    }
    userlistall = async (ids) => {
        const userlistallqqq = await Axios.post('page/authorprofile.php',{
            userids:ids,
        });
        return userlistallqqq.data;
    }
    userpreviewall = async (ids) => {
        const userpreviewall = await Axios.post('page/userpreviewall.php',{
            ids:ids,
        });
        return userpreviewall.data;
    }
    publisherpreviewall = async (ids) => {
        const publisherpreviewallsss = await Axios.post('page/publisherpreviewall.php',{
            ids:ids,
        });
        return publisherpreviewallsss.data;
    }
    articlepreviewall = async (ids) => {
        const articlepreviewallsss = await Axios.post('page/articlepreviewall.php',{
            ids:ids,
        });
        return articlepreviewallsss.data;
    }
    poempreviewall = async (ids) => {
        const poempreviewallsss = await Axios.post('page/poempreviewall.php',{
            ids:ids,
        });
        return poempreviewallsss.data;
    }
    
    interviewpreviewall = async (ids) => {
        const interviewpreviewallsss = await Axios.post('page/interviewpreviewall.php',{
            ids:ids,
        });
        return interviewpreviewallsss.data;
    }
    Authorslist  = async (pageNumber) => {
        const Authorslist = await Axios.get(`page/authorsall.php?page=${pageNumber}`);
        return Authorslist.data;
    }
    addnewsletters = async (user) => {
        const addnewsletterssss = await Axios.post('addnewsletters.php',{
            email:user.email,
        });
        return addnewsletterssss.data;
    }

    render(){
        const contextValue = {
            
            rootState:this.state,
            isLoggedIn:this.isLoggedIn,
            logoutUser:this.logoutUser,
            RegisterUser:this.RegisterUser,
            LoginUser:this.LoginUser,
            ForgetMailpassword:this.ForgetMailpassword,
            ChangePasswordall:this.ChangePasswordall,
            userdetailsall:this.userdetailsall,
            editprofileall:this.editprofileall,
            Booksaddall:this.Booksaddall,
            userbooksall:this.userbooksall,
            bookgetidall:this.bookgetidall,
            editbookall:this.editbookall,
            AudioBooksaddall:this.AudioBooksaddall,
            editaudiobookall:this.editaudiobookall,
            booktrailersall:this.booktrailersall,
            Booktraileraddall:this.Booktraileraddall,
            booktrailergetidall:this.booktrailergetidall,
            Booktrailereditall:this.Booktrailereditall,
            bookpaysmsg:this.bookpaysmsg,
            bookpaypalmsg:this.bookpaypalmsg,
            userpublishersall:this.userpublishersall,
            userpoemall:this.userpoemall,
            userarticleall:this.userarticleall,
            publisheraddall:this.publisheraddall,
            articleaddall:this.articleaddall,
            poemaddall:this.poemaddall,
            pubsgetidall:this.pubsgetidall,
            poemgetidall:this.poemgetidall,
            articlegetidall:this.articlegetidall,
            publishereditall:this.publishereditall,
            articleeditall:this.articleeditall,
            poemeditall:this.poemeditall,
            trailerpaysmsg:this.trailerpaysmsg,
            publisherpaysmsg:this.publisherpaysmsg,
            bookpayreceipt:this.bookpayreceipt,
            interviewaddall:this.interviewaddall,
            userinterviewsall:this.userinterviewsall,
            interviewsgetidall:this.interviewsgetidall,
            intervieweditall:this.intervieweditall,
            intervieweditalltwo:this.intervieweditalltwo,
            emailverifysall:this.emailverifysall,
            interviewsvideohomeall:this.interviewsvideohomeall,
            interviewshomeall:this.interviewshomeall,
            bookshomeall:this.bookshomeall,
            bookstrailerhomeall:this.bookstrailerhomeall,
            articlehomeall:this.articlehomeall,
            poemhomeall:this.poemhomeall,
            publisherhomeall:this.publisherhomeall,
            qavideohomeall:this.qavideohomeall,
            AllArticlelist:this.AllArticlelist,
            AllPeomlist:this.AllPeomlist,
            Allbooktrailerlist:this.Allbooktrailerlist,
            Allinterviewlist:this.Allinterviewlist,
            Allaudiobookslist:this.Allaudiobookslist,
            Allbookslist:this.Allbookslist,
            Allpublisherlist:this.Allpublisherlist,
            bookpreviewall:this.bookpreviewall,
            userlistall:this.userlistall,
            userpreviewall:this.userpreviewall,
            publisherpreviewall:this.publisherpreviewall,
            articlepreviewall:this.articlepreviewall,
            poempreviewall:this.poempreviewall,
            interviewaddtwoall:this.interviewaddtwoall,
            interviewpreviewall:this.interviewpreviewall,
            Authorslist:this.Authorslist,
            trailerpaysmsgpaypal:this.trailerpaysmsgpaypal,
            publisherpaysmsgpaypal:this.publisherpaysmsgpaypal,
            addnewsletters:this.addnewsletters,


        }
        return(
            <MyContext.Provider value={contextValue}>
                {this.props.children}
            </MyContext.Provider>
        )
    }
}

export default MyContextProvider;