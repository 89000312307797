
//export const WEB_URL_UPLOAD = "http://localhost/probooks/api";
//export const WEB_URL = "http://192.168.0.128/probooks/api";

export const WEB_URL_UPLOAD = "https://authoriview.com/api/";
export const WEB_URL = "https://authoriview.com/api/";

 export const CAPTCHA_SITE_KEY = "6Ld-9wgfAAAAAKqwWZvWEZS-K8KfuoMWm-W7D7rR";

 // Paypal Link
export const PAYPAL_URL = "https://authoriview.com/api/paypal/request.php";
export const PAYPAL_URL_TRAILER = "https://authoriview.com/api/paypal/requesttrailer.php";
export const PAYPAL_URL_PUBLISHER = "https://authoriview.com/api/paypal/requestpublisher.php";


// New paypal php code
//https://github.com/paypal/PayPal-PHP-SDK/releases
// https://www.evoluted.net/thinktank/web-development/using-paypals-rest-api-with-php-and-mysql

//var CLIENT ='AUJoKVGO3q1WA1tGgAKRdY6qx0qQNIQ6vl6D3k7y64T4qh5WozIQ7V3dl3iusw5BwXYg_T5FzLCRguP8';
//var SECRET ='EOw8LNwDhM7esrQ3nHfzKc7xiWnJc83Eawln4YLfUgivfx1LGzu9Mj0F5wlarilXDqdK9Q5aHVo-VGjJ';

//another source code
//https://www.sanwebe.com/2014/09/paypal-rest-api-payment-system

// paypal json requset sample
//https://developer.paypal.com/docs/integration/paypal-plus/mexico-brazil/create-a-payment-request/
//https://daniela-zimmermann-immobilien.at/wp-content/plugins/estatik/vendor/paypal/rest-api-sdk-php/sample/payments/UpdatePayment.php